<script>
  import { cubicInOut } from "svelte/easing"
  import { fade, fly } from "svelte/transition"
  import { Confetti } from "svelte-confetti"

  let showConfetti = false
  let showPopup = false

  function handleLogoClick() {
    showConfetti = true
    showPopup = true

    // Hide confetti and popup after 4 seconds
    setTimeout(() => {
      showConfetti = false
      showPopup = false
    }, 4000)
  }
</script>

{#if showConfetti}
  <Confetti infinite={false} duration={3000} amount={100} />
{/if}

<div class="relative flex items-center justify-center h-[324px]">
  <!-- Small blue dots -->
  <div class="absolute -z-10">
    <svg
      class="fill-purple-500"
      xmlns="http://www.w3.org/2000/svg"
      width="164"
      height="41"
      viewBox="0 0 164 41"
      fill="none"
    >
      <circle cx="1" cy="8" r="1" fill-opacity="0.24" />
      <circle cx="1" cy="1" r="1" fill-opacity="0.16" />
      <circle cx="1" cy="15" r="1" />
      <circle cx="1" cy="26" r="1" fill-opacity="0.64" />
      <circle cx="1" cy="33" r="1" fill-opacity="0.24" />
      <circle cx="8" cy="8" r="1" />
      <circle cx="8" cy="15" r="1" />
      <circle cx="8" cy="26" r="1" fill-opacity="0.24" />
      <circle cx="15" cy="15" r="1" fill-opacity="0.64" />
      <circle cx="15" cy="26" r="1" fill-opacity="0.16" />
      <circle cx="8" cy="33" r="1" />
      <circle cx="1" cy="40" r="1" />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 164 7)"
        fill-opacity="0.24"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 164 0)"
        fill-opacity="0.16"
      />
      <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 14)" />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 164 25)"
        fill-opacity="0.64"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 164 32)"
        fill-opacity="0.24"
      />
      <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 157 7)" />
      <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 157 14)" />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 157 25)"
        fill-opacity="0.24"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 150 14)"
        fill-opacity="0.64"
      />
      <circle
        cx="1"
        cy="1"
        r="1"
        transform="matrix(-1 0 0 1 150 25)"
        fill-opacity="0.16"
      />
      <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 157 32)" />
      <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 39)" />
    </svg>
  </div>
  <!-- Blue glow -->
  <div class="absolute -z-10">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="432"
      height="160"
      viewBox="0 0 432 160"
      fill="none"
    >
      <g opacity="0.6" filter="url(#filter0_f_2044_9)">
        <path
          class="fill-purple-500"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M80 112C62.3269 112 48 97.6731 48 80C48 62.3269 62.3269 48 80 48C97.6731 48 171 62.3269 171 80C171 97.6731 97.6731 112 80 112ZM352 112C369.673 112 384 97.6731 384 80C384 62.3269 369.673 48 352 48C334.327 48 261 62.3269 261 80C261 97.6731 334.327 112 352 112Z"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2044_9"
          x="0"
          y="0"
          width="432"
          height="160"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="32"
            result="effect1_foregroundBlur_2044_9"
          />
        </filter>
      </defs>
    </svg>
  </div>
  <!-- Diagonal lines -->
  <div
    class="absolute -z-10 w-[600px] top-1/2 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent rotate-[20deg] mix-blend-multiply"
    style="left: 50%; transform: translateX(-50%) rotate(20deg);"
  ></div>
  <div
    class="absolute -z-10 w-[600px] top-1/2 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent -rotate-[20deg] mix-blend-multiply"
    style="left: 50%; transform: translateX(-50%) rotate(-20deg);"
  ></div>
  <!-- Vertical lines -->
  <div
    class="absolute -z-10 h-full left-1/2 w-px bg-gradient-to-b from-gray-200 via-gray-200/50 to-transparent -translate-x-[216px] mix-blend-multiply"
    style="opacity: 0.7;"
  ></div>
  <div
    class="absolute -z-10 h-full left-1/2 w-px bg-gradient-to-t from-gray-200 via-gray-200/50 to-transparent translate-x-[216px] mix-blend-multiply"
    style="opacity: 0.7;"
  ></div>

  <!-- Popup message -->
  {#if showPopup}
    <div
      class="absolute bg-white p-4 rounded-lg shadow-lg z-50"
      transition:fly={{ y: -20, duration: 300 }}
    >
      <div class="text-lg font-semibold mb-2">🎉 Congratulations!</div>
      <div class="text-gray-600">You've found the hidden code:</div>
      <div class="text-purple-600 font-bold mt-1">PROMOAMP2025</div>
    </div>
  {/if}
  <!-- Logos -->
  <div
    class="absolute before:absolute before:-inset-3 before:rounded-full before:[background:conic-gradient(from_180deg,transparent,theme(colors.purple.500))_border-box] before:border before:border-transparent before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] before:[mask-composite:exclude_!important] before:animate-[spin_3s_linear_infinite]"
  >
    <button
      class="animate-[breath_8s_ease-in-out_infinite_both] cursor-pointer"
      on:click={handleLogoClick}
    >
      <div
        class="flex items-center justify-center w-32 h-32 bg-white border border-purple-500 rounded-full shadow-lg shadow-black/[0.03] drop-shadow-[0_0_15px_rgba(168,85,247,0.35)] before:absolute before:inset-0 before:m-[8.334%] before:bg-purple-200/60 before:border before:border-purple-700/5 before:rounded-[inherit] before:[mask-image:linear-gradient(to_bottom,black,transparent)]"
      >
        <img
          class="relative m-auto pl-1"
          src="/PromoAmpLogo-Purple.svg"
          width="60"
          height="60"
          alt="Promo Amp Logo"
        />
      </div>
    </button>
  </div>

  <!-- Panel #1 -->
  <div
    class="w-full h-full flex items-center justify-center"
    in:fade={{ duration: 700, easing: cubicInOut }}
    out:fade={{ duration: 300, easing: cubicInOut }}
    style="transform: scale(1); opacity: 1;"
  >
    <div class="absolute -translate-x-[136px]">
      <div class="animate-[breath_7s_ease-in-out_3s_infinite_both]">
        <div
          class="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:bg-gray-200/60 before:border before:border-gray-700/5 before:rounded-[inherit] before:[mask-image:linear-gradient(to_bottom,black,transparent)]"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/x.svg"
            width="23"
            height="22"
            alt="Logo 02"
          />
        </div>
      </div>
    </div>
    <div class="absolute translate-x-[136px]">
      <div class="animate-[breath_7s_ease-in-out_3.5s_infinite_both]">
        <div
          class="flex items-center justify-center w-16 h-16 bg-white rounded-full shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:bg-gray-200/60 before:border before:border-gray-700/5 before:rounded-[inherit] before:[mask-image:linear-gradient(to_bottom,black,transparent)]"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/whatsapp.svg"
            width="22"
            height="22"
            alt="Logo 03"
          />
        </div>
      </div>
    </div>
    <div class="absolute -translate-x-[216px] -translate-y-[82px]">
      <div class="animate-[breath_6s_ease-in-out_3.5s_infinite_both]">
        <div
          class="flex items-center justify-center w-20 h-20 bg-white rounded-full shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:bg-gray-200/60 before:border before:border-gray-700/5 before:rounded-[inherit] before:[mask-image:linear-gradient(to_bottom,black,transparent)]"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/reddit.svg"
            width="24"
            height="22"
            alt="Logo 04"
          />
        </div>
      </div>
    </div>
    <div class="absolute translate-x-[216px] -translate-y-[82px]">
      <div class="animate-[breath_6s_ease-in-out_1.5s_infinite_both]">
        <div
          class="flex items-center justify-center w-20 h-20 bg-white rounded-full shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:bg-gray-200/60 before:border before:border-gray-700/5 before:rounded-[inherit] before:[mask-image:linear-gradient(to_bottom,black,transparent)]"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/instagram.svg"
            width="25"
            height="25"
            alt="Logo 05"
          />
        </div>
      </div>
    </div>
    <div class="absolute translate-x-[216px] translate-y-[82px]">
      <div class="animate-[breath_6s_ease-in-out_2s_infinite_both]">
        <div
          class="flex items-center justify-center w-20 h-20 bg-white rounded-full shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:bg-gray-200/60 before:border before:border-gray-700/5 before:rounded-[inherit] before:[mask-image:linear-gradient(to_bottom,black,transparent)]"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/youtube.svg"
            width="25"
            height="23"
            alt="Logo 06"
          />
        </div>
      </div>
    </div>
    <div class="absolute -translate-x-[216px] translate-y-[82px]">
      <div class="animate-[breath_6s_ease-in-out_2.5s_infinite_both]">
        <div
          class="flex items-center justify-center w-20 h-20 bg-white rounded-full shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:bg-gray-200/60 before:border before:border-gray-700/5 before:rounded-[inherit] before:[mask-image:linear-gradient(to_bottom,black,transparent)]"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/tiktok.svg"
            width="25"
            height="25"
            alt="Logo 07"
          />
        </div>
      </div>
    </div>
    <div class="absolute -translate-x-[292px] opacity-40">
      <div class="animate-[breath_6s_ease-in-out_2s_infinite_both]">
        <div
          class="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-lg border border-gray-200/60"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/discord.svg"
            width="20"
            height="20"
            alt="Logo 08"
          />
        </div>
      </div>
    </div>
    <div class="absolute translate-x-[292px] opacity-40">
      <div class="animate-[breath_6s_ease-in-out_4s_infinite_both]">
        <div
          class="flex items-center justify-center w-12 h-12 bg-white rounded-full shadow-lg border border-gray-200/60"
        >
          <img
            class="relative"
            src="https://img.promoamp.com/icons/telegram.svg"
            width="21"
            height="13"
            alt="Logo 09"
          />
        </div>
      </div>
    </div>
  </div>
</div>
